import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { updateSelectedNumbers } from '../../redux/features/select-numbers-slice';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';

export interface INumbersSelectorUIProps {
  selectedNumbers: number[];
  handleChange: (selectedNumber: number) => (event: ChangeEvent<HTMLInputElement>) => void;
}

const useNumberSelector = (): INumbersSelectorUIProps => {
  const selectedNumbers = useSelector((state: RootState) => state.selectedNumbers);
  const dispatch = useAppDispatch();
  const handleChange = (selectedNumber: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const notPresent = selectedNumbers.findIndex((x) => x === selectedNumber) === -1;

    // eslint-disable-next-line no-nested-ternary
    const updatedTableSelection =
      event.target.checked && notPresent
        ? [...selectedNumbers, selectedNumber]
        : !event.target.checked
        ? selectedNumbers.filter((x) => x !== selectedNumber)
        : selectedNumbers;

    dispatch(updateSelectedNumbers(updatedTableSelection));
  };

  return { selectedNumbers, handleChange };
};

export default useNumberSelector;
