import { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';

import SelectComponent from '../../shared/components/SelectComponent';
import { TimePerQuestionOptions } from '../../shared/constants';
import { MenuItem } from '@mui/material';
import { allowedTimePerQuestionUpdated } from '../../redux/features/allowed-time-per-question-slice';

import { useTranslation } from 'react-i18next';

const useAllowedTimePerQuestion = () => {
  const dispatch = useAppDispatch();

  const allowedTimePerQuestion = useSelector((state: RootState) => state.allowedTimePerQuestion);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(allowedTimePerQuestionUpdated(event.target.value as string));
  };

  return { allowedTimePerQuestion, handleChange };
};

const AllowedTimePerQuestion = () => {
  const { t } = useTranslation();

  const selectOptions = TimePerQuestionOptions.map((m) => (
    <MenuItem key={m} value={m}>
      {t('time-per-question.second', { count: m })}
    </MenuItem>
  ));

  const { allowedTimePerQuestion, handleChange } = useAllowedTimePerQuestion();
  return (
    <Grid container>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <SelectComponent
          selectOptions={selectOptions}
          selectedValue={allowedTimePerQuestion}
          handleChange={handleChange}
          selectLabel={t('time-per-question.second.select-1.label-text')}
          formControlSx={{ m: 1, minWidth: 216 }}
          labelId="allowedTimePerQuestionSelectLabel"
          selectId="allowedTimePerQuestionSelectId"
        />
      </Grid>
    </Grid>
  );
};

export default AllowedTimePerQuestion;
