import { tNumber, practiceOptions } from './constants';
import i18next from 'i18next';

export function localizedNumberToEnglishNumber(numberString: string, locale: string | undefined): number {
  if (!locale) return NaN;
  // Create a NumberFormat instance for the specified locale
  const formatter = new Intl.NumberFormat(locale);

  // Get the group and decimal separators for the given locale
  const groupSeparator = formatter.formatToParts(12345.6).find((part) => part.type === 'group')?.value;
  const decimalSeparator = formatter.formatToParts(12345.6).find((part) => part.type === 'decimal')?.value;

  if (!decimalSeparator) return NaN;
  // If the group separator is detected, remove all occurrences of it
  const sanitizedString = numberString
    .replace(new RegExp(`\\${groupSeparator}`, 'g'), '') // Remove group separator
    .replace(decimalSeparator, '.'); // Replace decimal separator with '.'
  // Parse the sanitized string as a float
  const parsedNumber = parseFloat(sanitizedString);
  // Return the parsed number (or NaN if it's invalid)
  return parsedNumber;
}

export function naNasString(): string {
  return NaN as unknown as string;
}
// Example usage:

// const germanNumber = '9.999,99'; // Germany uses dot as group separator and comma as decimal separator
// const usNumber = '9,999.99'; // USA uses comma as group separator and dot as decimal separator

// console.log(parseLocalizedNumber(germanNumber, 'de-DE')); // 9999.99
// console.log(parseLocalizedNumber(usNumber, 'en-US')); // 9999.99

export function generateAnswerAndOperationChar(operation: string, firstNumber: number, secondNumber: number) {
  switch (operation) {
    case practiceOptions.Additions:
      return {
        operationChar: localizeOperationChar(operation),
        correctAnswer: firstNumber + secondNumber,
      };
    case practiceOptions.Subtractions:
      return {
        operationChar: localizeOperationChar(operation),
        correctAnswer: firstNumber - secondNumber,
      };
    case practiceOptions.Multiplications:
      return {
        operationChar: localizeOperationChar(operation),
        correctAnswer: firstNumber * secondNumber,
      };
    case practiceOptions.Divisions:
      return { operationChar: localizeOperationChar(operation), correctAnswer: firstNumber };
    case practiceOptions.Fractions:
      return {
        operationChar: localizeOperationChar(operation),
        correctAnswer: firstNumber % secondNumber,
      };
    default:
      return {
        operationChar: localizeOperationChar(operation),
        correctAnswer: firstNumber + secondNumber,
      };
  }
}

export function localizeOperationChar(operation: string) {
  switch (operation) {
    case practiceOptions.Additions:
      return i18next.t('practice-options.addition.operation-character');
    case practiceOptions.Subtractions:
      return i18next.t('practice-options.subtraction.operation-character');
    case practiceOptions.Multiplications:
      return i18next.t('practice-options.multiplication.operation-character');
    case practiceOptions.Divisions:
      return i18next.t('practice-options.division.operation-character');
    case practiceOptions.Fractions:
      return i18next.t('practice-options.fraction.operation-character');
    default:
      return i18next.t('practice-options.addition.operation-character');
  }
}

export const localizeNumber = (numberToLocalize: number) => i18next.t(tNumber, { val: numberToLocalize });
