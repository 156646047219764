import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const selectNumbersSlice = createSlice({
  name: 'selectNumbers',
  initialState: [1, 2, 3, 4, 5] as number[],
  reducers: {
    updateSelectedNumbers: (state, action: PayloadAction<number[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});
export const { updateSelectedNumbers } = selectNumbersSlice.actions;
export default selectNumbersSlice.reducer;
