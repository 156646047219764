import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import TextFieldComponent from '../../shared/components/TextFieldComponent';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { yourNameUpdated } from '../../redux/features/your-name-slice';

const YourNameTextField = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const yourName = useAppSelector((state) => state.yourName);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(yourNameUpdated(value));
  };

  return (
    <Grid container>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <TextFieldComponent
          variant="outlined"
          id="standard-required"
          label={t('yourname.text-field.label')}
          value={yourName}
          onChange={handleChange}
          autoFocus
        />
      </Grid>
    </Grid>
  );
};

export default YourNameTextField;
