import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import numberOfQuestionsSlice from '../redux/features/number-of-questions-slice';
import selectNumberSlice from '../redux/features/select-numbers-slice';
import whatToPracticeSlice from '../redux/features/what-to-practice-slice';
import allowedTimeSlice from '../redux/features/allowed-time-slice';
import timeLeftSlice from '../redux/features/time-left-slice';
import allowedTimePerQuestionSlice from '../redux/features/allowed-time-per-question-slice';
import yourNameSlice from '../redux/features/your-name-slice';

export const store = configureStore({
  reducer: {
    selectedNumbers: selectNumberSlice,
    whatToPractice: whatToPracticeSlice,
    numberOfQuestions: numberOfQuestionsSlice,
    allowedTime: allowedTimeSlice,
    timeLeft: timeLeftSlice,
    allowedTimePerQuestion: allowedTimePerQuestionSlice,
    yourName: yourNameSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
