import { AppBar, Box, IconButton, Link, Toolbar } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink as RouterLink } from 'react-router-dom';
import styles from './header-navbar.module.scss';
import SwipeableNavDrawer from './NavDrawer';
import { useTranslation } from 'react-i18next';
import LocaleSwitcher from './LanguageSwitcher';

export const linkActive = {
  '&.active': {
    textDecoration: 'underline rgb(156, 39, 176) solid 3px',
  },
  '&:hover': {
    backgroundColor: 'rgb(156, 39, 176)',
  },
  color: 'white',
  mx: 1,
  textAlign: 'left',
};

const HeaderNavbar = ({ headerText }: { headerText: string }) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side: string, open: boolean) => {
    setState({ ...state, [side]: open });
  };
  return (
    <div className={styles.root}>
      <SwipeableNavDrawer toggleDrawer={toggleDrawer} open={state.left} />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => toggleDrawer('left', true)}
            edge="start"
            className={styles.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <img src="./logo.svg" alt="logo" className={styles.logo} /> &nbsp;
          <Link
            component={RouterLink}
            sx={{
              ...linkActive,
              fontSize: 24,
              '&:hover': {
                backgroundColor: '',
              },
            }}
            className={styles.title}
            to="/"
          >
            {headerText}
          </Link>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div style={{ display: 'flex' }}>
              <Link component={RouterLink} sx={linkActive} to="/" className={styles.link}>
                {t('navbar.home.link.text')}
              </Link>
              <Link component={RouterLink} sx={linkActive} to="/quiz-options" className={styles.link}>
                {t('navbar.quiz-options.link.text')}
              </Link>
              <Link component={RouterLink} sx={linkActive} to="/learn-tables" className={styles.link}>
                {t('navbar.learn-tables.link.text')}
              </Link>
              <Link component={RouterLink} sx={linkActive} to="/practice" className={styles.link}>
                {t('navbar.start-quiz.link.text')}
              </Link>
              <Link component={RouterLink} sx={linkActive} to="/about" className={styles.link}>
                {t('navbar.about.link.text')}
              </Link>
              <Link component={RouterLink} sx={linkActive} to="/help" className={styles.link}>
                {t('navbar.help.link.text')}
              </Link>
            </div>
          </Box>
          <LocaleSwitcher></LocaleSwitcher>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderNavbar;
