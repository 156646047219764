import { FormControl, InputLabel, Select, SelectChangeEvent, SelectProps, SxProps, ThemeProvider } from '@mui/material';

import variables from '../../_variables.module.scss';
import { whiteTheme } from '../../components/SelectionPage/WhatToPracticeSelector';

import styles from './select-component.module.scss';

export type SelectComponentProps = SelectProps & {
  selectLabel: string;
  selectedValue: string | string[] | number;
  handleChange: (event: SelectChangeEvent<any>) => void;
  selectOptions: JSX.Element[];
  formControlSx?: SxProps;
  selectId: string;
  labelId: string;
};

const SelectComponent = ({
  selectOptions,
  selectLabel,
  selectedValue,
  handleChange,
  formControlSx = { minWidth: 216, m: 2 },
  labelId,
  selectId,
  sx,
  ...rest
}: SelectComponentProps) => {
  return (
    <ThemeProvider theme={whiteTheme}>
      <FormControl sx={formControlSx} className={styles.formControl} variant={rest.variant ?? 'outlined'}>
        <InputLabel id={labelId} htmlFor={selectId} sx={{ color: variables.selectColor }}>
          {selectLabel}
        </InputLabel>
        <Select
          labelId={labelId}
          id={selectId}
          label={selectLabel}
          value={selectedValue}
          onChange={handleChange}
          sx={{
            ...sx,
            color: variables.selectColor,
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: variables.selectColor,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: variables.selectColor,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: variables.selectColor,
            },
            '.MuiSvgIcon-root': {
              fill: 'white !important',
            },
            // Root class for the input field
            '& .MuiOutlinedInput-root': {
              color: variables.selectColor,
              fontFamily: 'Arial',
              fontWeight: 'bold',
              // Class for the border around the input field
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: variables.selectColor,
                borderWidth: '2px',
              },
            },
            // // Class for the label of the input field
            '& .MuiInputLabel-outlined': {
              color: variables.selectColor,
              fontWeight: 'bold',
            },

            '& .MuiSvgIcon-root': {
              color: variables.selectColor,
            },
          }}
          {...rest}
        >
          {selectOptions}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default SelectComponent;
