import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const timeLeftSlice = createSlice({
  name: 'timeLeft',
  initialState: 180,
  reducers: {
    timerUpdated: (state, action: PayloadAction<number>) => action.payload,
    allowedTimeUpdated: (state, action: PayloadAction<string>) => Number.parseInt(action.payload, 10) * 60,
    startPracticeAgain: (state, action: PayloadAction<string>) => Number.parseInt(action.payload, 10) * 60,
  }
});

export default timeLeftSlice.reducer;
export const { timerUpdated, allowedTimeUpdated, startPracticeAgain } = timeLeftSlice.actions;