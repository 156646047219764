import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const allowedTimeSlice = createSlice({
  name: 'allowedTime',
  initialState: '3',
  reducers: {
    timeUpdated: (_state, action: PayloadAction<string>) => action.payload
  }
});

export const { timeUpdated } = allowedTimeSlice.actions;
export default allowedTimeSlice.reducer;