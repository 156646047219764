import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const allowedTimePerQuestionSlice = createSlice({
  name: 'allowedTimePerQuestion',
  initialState: '0',
  reducers: {
    allowedTimePerQuestionUpdated: (_state, action: PayloadAction<string>) => action.payload,
  },
});

export const { allowedTimePerQuestionUpdated } = allowedTimePerQuestionSlice.actions;
export default allowedTimePerQuestionSlice.reducer;
