import { Grid2 as Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import styles from './not-found.module.scss';
import { Link as RouterLink } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('not-found-page.title');
  }, [t]);

  return (
    <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ margin: 14 }}>
      <Trans i18nKey="not-found-page.p1.text">
        <Typography variant="h2" style={{ fontSize: 24 }} className={styles.style} align="center">
          <p>
            The page you are looking for can not be found. Plesae click
            <Link sx={{ color: 'white', textDecoration: 'underline' }} component={RouterLink} to="/">
              here
            </Link>
            to go to the home page.
          </p>
        </Typography>
      </Trans>
    </Grid>
  );
};

export default NotFound;
