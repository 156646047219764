import { memo } from 'react';
import { createTheme, TextField, TextFieldProps, ThemeProvider } from '@mui/material';
import variables from '../../_variables.module.scss';

export const whiteTextFieldTheme = createTheme({
  palette: {
    primary: {
      main: variables.baseColor,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            color: variables.baseColor,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: variables.baseColor,
              borderWidth: '2px',
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: variables.baseColor,
                borderWidth: '3px',
              },
            },
            '&:hover:not(.Mui-focused)': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: variables.baseColor,
              },
            },
          },
          '& .MuiInputLabel-outlined': {
            color: variables.baseColor,
            fontWeight: 'bold',
            '&.Mui-focused': {
              color: variables.baseColor,
            },
          },
        },
      },
    },
  },
});

type AppTextFieldProps = TextFieldProps & {};

const TextFieldComponent = ({ value, onChange: onUpdate, ...rest }: AppTextFieldProps) => {
  return (
    <ThemeProvider theme={whiteTextFieldTheme}>
      <TextField {...rest} value={value} onChange={onUpdate} />
    </ThemeProvider>
  );
};

export default memo(TextFieldComponent);
