import { Grid2 as Grid, Typography } from '@mui/material';
import variables from '../_variables.module.scss';
// see the solution here https://stackoverflow.com/questions/70149171/webpack-scss-cannot-resolve-background-image-url
import md5Image from '../images/md5.jpg';
import styles from './loading.module.scss';
import { useTranslation } from 'react-i18next';
const Loading = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))`,
        color: variables.selectColor,
        border: '20px solid transparent',
        borderImage: `url(${md5Image}) 120 round`,
        width: '100%',
      }}
    >
      <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ margin: 8 }}>
        <Typography className={styles.style} style={{ fontSize: 32 }}>
          {t('loading-component.text')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Loading;
