import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const numberOfQuestionsSlice = createSlice({
  name: 'numberOfQuestions',
  initialState: 15,
  reducers: {
    updateNumberOfQuestions: (_state, action: PayloadAction<number>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase('START_PRACTICE_AGAIN', () => 20);
  },
});

export const { updateNumberOfQuestions } = numberOfQuestionsSlice.actions;
export default numberOfQuestionsSlice.reducer;
