import PracticePageUI from './PracticePageUI';
import usePractice from './usePractice';

const Practice = () => {
  const {
    firstNumber,
    secondNumber,
    onAnswered,
    operationChar,
    previousAnswers,
    timeLeft,
    numberOfQuestions,
    handleStartAgainClick,
    toTimeString,
    toTimeTextString,
    allowedTime,
    enabled,
    remainingTime,
    numOfQuestionsLeft,
    quizDone,
    isCorrect,
    handleChange,
    answer,
    usedTime,
    wrongAnswers,
    answerInput,
    questionTimeLeft,
    timePerQuestionEnabled,
    yourName,
    yourNameContext,
  } = usePractice();

  return (
    <>
      <PracticePageUI
        firstNumber={firstNumber}
        secondNumber={secondNumber}
        onAnswered={onAnswered}
        operationChar={operationChar}
        previousAnswers={previousAnswers}
        timeLeft={timeLeft}
        numberOfQuestions={numberOfQuestions}
        handleStartAgainClick={handleStartAgainClick}
        toTimeString={toTimeString}
        toTimeTextString={toTimeTextString}
        allowedTime={allowedTime}
        enabled={enabled}
        remainingTime={remainingTime}
        numOfQuestionsLeft={numOfQuestionsLeft}
        quizDone={quizDone}
        isCorrect={isCorrect}
        handleChange={handleChange}
        answer={answer}
        usedTime={usedTime}
        wrongAnswers={wrongAnswers}
        answerInput={answerInput}
        questionTimeLeft={questionTimeLeft}
        timePerQuestionEnabled={timePerQuestionEnabled}
        yourName={yourName}
        yourNameContext={yourNameContext}
      />
    </>
  );
};

export default Practice;
