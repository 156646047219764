import { useEffect } from 'react';
import styles from './about.module.scss';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
const AboutPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('about-page.title');
  }, [t]);
  return (
    <>
      <Grid size={{ xs: 1, sm: 1, md: 1 }} />
      <Grid size={{ xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }}>
        <Typography variant="subtitle1" sx={{ fontSize: 22 }} className={styles.typography_style}>
          <p>{t('about-page.section-1.para-1')}</p>
          <p>{t('about-page.section-1.para-2')}</p>
        </Typography>
      </Grid>
      <Grid size={{ xs: 1, sm: 1, md: 1 }} />
    </>
  );
};

export default AboutPage;
