import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { practiceOptions } from '../../shared/constants';

const whatToPracticeSlice = createSlice({
  name: 'whatToPractice',
  initialState: [practiceOptions.Additions] as string[],
  reducers: {
    updateWhatToPracticeSelction: (state, action: PayloadAction<string[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { updateWhatToPracticeSelction } = whatToPracticeSlice.actions;
export default whatToPracticeSlice.reducer;
