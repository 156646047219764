import React from 'react';
import { useSelector } from 'react-redux';
import { updateWhatToPracticeSelction } from '../../redux/features/what-to-practice-slice';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { SelectChangeEvent } from '@mui/material';

interface IWhatToPracticeUIProps {
  handleClose: () => void;
  handleOpen: () => void;
  open: boolean;
  selectedPractices: string[];
  handlePracticeTypeSelectionChange: (event: SelectChangeEvent<string[]>) => void;
}
const useWhatToPracticeSelector = (): IWhatToPracticeUIProps => {
  const dispatch = useAppDispatch();
  const selectedPractices = useSelector((state: RootState) => state.whatToPractice);
  const handlePracticeTypeSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const practiceSelections = event.target.value;
    dispatch(updateWhatToPracticeSelction(practiceSelections as string[]));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return { handleClose, handleOpen: handleClickOpen, open, selectedPractices, handlePracticeTypeSelectionChange };
};

export default useWhatToPracticeSelector;
