import { Divider, ListItemText, MenuItem, MenuList, SwipeableDrawer } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import styles from './nav-drawer.module.scss';
import { useTranslation } from 'react-i18next';
import variables from '../_variables.module.scss';

export interface ISwipeableNavDrawer {
  open: boolean;
  toggleDrawer: (side: string, open: boolean) => void;
}
const SwipeableNavDrawer = (props: ISwipeableNavDrawer) => {
  const { toggleDrawer, open } = props;

  const { t } = useTranslation();
  const openClose = (side: string, openDrawer: boolean) => {
    toggleDrawer(side, openDrawer);
  };
  const closeDrawer = (side: string, openDrawer: boolean) => {
    toggleDrawer(side, openDrawer);
  };

  const sideList = (side: string) => (
    <div
      className={styles.list}
      role="presentation"
      onClick={() => closeDrawer(side, false)}
      onKeyDown={() => closeDrawer(side, false)}
    >
      <MenuList sx={{ backgroundColor: variables.navbarBackgroundcolor, padding: 0 }}>
        <RouterLink to="/" className={styles.link}>
          <MenuItem>
            <ListItemText primary={t('navbar.home.link.text')} />
          </MenuItem>
        </RouterLink>
        <Divider className={styles.divider} />
        <RouterLink to="/quiz-options" className={styles.link}>
          <MenuItem>
            <ListItemText primary={t('navbar.quiz-options.link.text')} />
          </MenuItem>
        </RouterLink>
        <Divider className={styles.divider} />
        <RouterLink to="/learn-tables" className={styles.link}>
          <MenuItem>
            <ListItemText primary={t('navbar.learn-tables.link.text')} />
          </MenuItem>
        </RouterLink>
        <Divider className={styles.divider} />
        <RouterLink to="/practice" className={styles.link}>
          <MenuItem>
            <ListItemText primary={t('navbar.start-quiz.link.text')} />
          </MenuItem>
        </RouterLink>
        <Divider className={styles.divider} />
        <RouterLink to="/about" className={styles.link}>
          <MenuItem>
            <ListItemText primary={t('navbar.about.link.text')} />
          </MenuItem>
        </RouterLink>
        <Divider className={styles.divider} />
        <RouterLink to="/help" className={styles.link}>
          <MenuItem>
            <ListItemText primary={t('navbar.help.link.text')} />
          </MenuItem>
        </RouterLink>
        <Divider className={styles.divider} />
      </MenuList>
    </div>
  );

  return (
    <SwipeableDrawer
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: variables.navbarBackgroundcolor,
        },
      }}
      variant="temporary"
      open={open}
      onClose={() => openClose('left', false)}
      onOpen={() => openClose('left', true)}
    >
      {sideList('left')}
    </SwipeableDrawer>
  );
};

export default SwipeableNavDrawer;
