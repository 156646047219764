import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { timerUpdated } from '../redux/features/time-left-slice';
import { useAppDispatch } from '../app/hooks';
import { RootState } from '../app/store';

export type TimerType = ReturnType<typeof setInterval>;
const useRemainingTime = ({ enabled }: { enabled: boolean }): string => {
  const dispatch = useAppDispatch();
  const timeLeft = useSelector((state: RootState) => state.timeLeft);
  useEffect(() => {
    let intervalId: TimerType = null as unknown as TimerType;
    if (!intervalId) {
      intervalId = setInterval(() => {
        if (enabled) {
          const newTime = timeLeft - 1;
          dispatch(timerUpdated(newTime));
        }
      }, 1000);
    }
    if (timeLeft <= 0) {
      clearInterval(intervalId);
      timerUpdated(0);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft - minutes * 60;
  const timeString = `${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`;

  return timeString;
};

export default useRemainingTime;
