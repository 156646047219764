import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IAnswer, IQuestion, IPracticePageUIProps } from './PracticePageUI';
import { practiceOptions } from '../shared/constants';
import { startPracticeAgain } from '../redux/features/time-left-slice';
import useRemainingTime from './useRemainingTime';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';

import { useTranslation } from 'react-i18next';
import { generateAnswerAndOperationChar } from '../shared/utils';

const usePractice = (): IPracticePageUIProps => {
  const { t } = useTranslation();

  const selectedNumbers = useAppSelector((state: RootState) => state.selectedNumbers);
  const whatToPractice = useAppSelector((state: RootState) => state.whatToPractice);
  const timeLeft = useAppSelector((state: RootState) => state.timeLeft);
  const numberOfQuestions = useAppSelector((state: RootState) => state.numberOfQuestions);
  const allowedTime = useAppSelector((state: RootState) => state.allowedTime);
  const timePerQuestion = useAppSelector((state) => state.allowedTimePerQuestion);
  const yourName = useAppSelector((state) => state.yourName);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [storedAnswers, setStoredAnswers] = useState<IAnswer[]>([]);
  const [answer, setAnswer] = useState('');
  const usedTime = storedAnswers.map((m) => m.timeUsed).reduce((a, b) => a + b, 0);
  const answerInput = React.createRef<HTMLInputElement>();

  const yourNameContext = !!yourName ? 'name' : 'noname';

  const enabled = numberOfQuestions > storedAnswers.length;
  useRemainingTime({ enabled });
  const toTimeString = (time: number) => {
    const minutes = Math.floor(time / 60);
    const minutesStr = t('numbers', { value: minutes });
    const secondsStr = t('numbers', { value: time - minutes * 60 });
    // const seconds = time - minutes * 60;
    const timeString = `${`${minutesStr}`}:${`${secondsStr}`}`;
    return timeString;
  };

  const toTimeTextString = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const mins = t('allowed-time.minute', { count: minutes });
    const secs = t('time.second', { count: seconds });
    const timeStr = `${mins} ${t('and')} ${secs}`;

    return timeStr;
  };

  const isCorrect = (answerGiven: string, correctAnswer: number) => Number.parseInt(answerGiven, 10) === correctAnswer;
  const wrongAnswers = storedAnswers.filter((a) => !isCorrect(a.answer, a.correctAnswer)).length;

  const remainingTime = toTimeTextString(timeLeft);
  const numOfQuestionsLeft = numberOfQuestions - storedAnswers.length;
  const quizDone = timeLeft <= 0 || storedAnswers.length >= numberOfQuestions;

  const timePerQuestionEnabled = !!timePerQuestion && Number.parseInt(timePerQuestion, 10) > 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value);

    // const val = parseLocalizedNumber(e.target.value, resolvedLocale as string);
    // if (!isNaN(val)) {
    //   // its a number
    //   setAnswer(t(NumLabel, { val: val }));
    // } else {
    //   // its not a number
    //   setAnswer(e.target.value);
    // }
  };
  const [active, setActive] = useState(false);
  const [timePerQuestionLeft, setTimePerQuestionLeft] = useState(!!timePerQuestion ? parseInt(timePerQuestion, 10) : 0);
  const [questionState, setQuestionState] = useState<IQuestion>({
    firstNumber: 0,
    secondNumber: 0,
    operationChar: practiceOptions.Additions,
    correctAnswer: 0,
    timeUsed: 0,
  });

  const { firstNumber, secondNumber, operationChar } = questionState;

  const generateNumbers = () => {
    let firstNum = questionState.firstNumber;
    let secondNum = questionState.secondNumber;

    do {
      const firstNumIndex = Math.floor(Math.random() * selectedNumbers.length);
      const secondNumIndex = Math.floor(Math.random() * selectedNumbers.length);
      firstNum = selectedNumbers[firstNumIndex];
      secondNum = selectedNumbers[secondNumIndex];
      if (firstNum < secondNum) {
        const temp = firstNum;
        firstNum = secondNum;
        secondNum = temp;
      }
    } while (
      selectedNumbers.length > 1 &&
      firstNum === questionState.firstNumber &&
      secondNum === questionState.secondNumber
    );

    const operation = whatToPractice[Math.floor(Math.random() * whatToPractice.length)];

    const { operationChar: operatorChar, correctAnswer } = generateAnswerAndOperationChar(
      operation,
      firstNum,
      secondNum,
    );

    firstNum = operation === practiceOptions.Divisions ? firstNum * secondNum : firstNum;

    setQuestionState({
      firstNumber: firstNum,
      secondNumber: secondNum,
      operationChar: operatorChar,
      correctAnswer,
      timeUsed: 0,
    });
  };

  const handleAnswer = (answerToStore: string) => {
    storeAnswer(answerToStore);
    generateNumbers();
    setAnswer('');
    answerInput?.current?.focus();
    setTimePerQuestionLeft((_) => parseInt(timePerQuestion));
  };

  const questionTimeLeft = timePerQuestionLeft;

  useEffect(() => {
    if (selectedNumbers.length <= 0 || whatToPractice.length <= 0) {
      toast.info(t('practice-page.numbers-what-to-practice.not-selected.message'));
      navigate('/quiz-options');
    } else {
      generateNumbers();
      document.title = 'tifle - Practice maths';
      answerInput?.current?.focus();
      dispatch(startPracticeAgain(allowedTime));
      setActive(true);
      setTimePerQuestionLeft(parseInt(timePerQuestion, 10));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (active && timePerQuestionEnabled && timeLeft < parseInt(allowedTime, 10) * 60) {
      const newVal = timePerQuestionLeft - 1;
      if (newVal <= 0) {
        handleAnswer(answer);
      } else {
        setTimePerQuestionLeft((_) => newVal);
      }
    }
  }, [active, timePerQuestionEnabled, timeLeft]);

  const storeAnswer = (answerToStore: string) => {
    const timeUsed =
      Number.parseInt(allowedTime, 10) * 60 -
      timeLeft -
      storedAnswers.map((m) => m.timeUsed).reduce((a, b) => a + b, 0);
    const state = { ...questionState, answer: answerToStore, timeUsed };
    setStoredAnswers([...storedAnswers, state]);
  };

  // Add capabilities for timer per questions
  //   along with the totoal time.

  const handleStartAgainClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setStoredAnswers([]);
    dispatch(startPracticeAgain(allowedTime));
  };

  return {
    firstNumber,
    secondNumber,
    onAnswered: handleAnswer,
    operationChar,
    previousAnswers: storedAnswers,
    timeLeft,
    numberOfQuestions,
    handleStartAgainClick,
    toTimeString,
    toTimeTextString,
    allowedTime,
    enabled,
    remainingTime,
    numOfQuestionsLeft,
    quizDone,
    isCorrect,
    handleChange,
    answer,
    usedTime,
    wrongAnswers,
    answerInput,
    questionTimeLeft,
    timePerQuestionEnabled,
    yourName,
    yourNameContext,
  };
};

export default usePractice;
