import { updateNumberOfQuestions } from '../../redux/features/number-of-questions-slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { SelectChangeEvent } from '@mui/material';

export interface INumberOfQuestionsComponentProps {
  handleChange: (event: SelectChangeEvent<number>) => void;
  numberOfQuestions: number;
}

export const useNumberOfQuestionsComponent = (): INumberOfQuestionsComponentProps => {
  const dispatch = useAppDispatch();
  const numberOfQuestions = useAppSelector((state: RootState) => state.numberOfQuestions);

  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(updateNumberOfQuestions(Number.parseInt(event.target.value as string, 10)));
  };

  return { handleChange, numberOfQuestions };
};

export default useNumberOfQuestionsComponent;
