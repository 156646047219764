import { Checkbox, Grid2 as Grid, ListItemText, MenuItem } from '@mui/material';
import { practiceOptions } from '../../shared/constants';
import useWhatToPracticeSelector from './useWhatToPracticeSelector';
import styles from './WhatToPracticeSelector.module.scss';
import { createTheme } from '@mui/material/styles';
import SelectComponent from '../../shared/components/SelectComponent';
import { green } from '@mui/material/colors';

import { useTranslation } from 'react-i18next';

export const whiteTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});
const WhatToPracticeSelector = () => {
  const { t } = useTranslation();

  const { selectedPractices, handlePracticeTypeSelectionChange } = useWhatToPracticeSelector();
  const selectOptions = Object.values(practiceOptions).map((practice) => (
    <MenuItem key={practice} value={practice}>
      <Checkbox
        sx={{
          color: green[800],
          '&.Mui-checked': {
            color: green[600],
          },
        }}
        checked={selectedPractices.indexOf(practice) > -1}
      />
      <ListItemText primary={t(practice)} />
    </MenuItem>
  ));

  return (
    <Grid container>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <div className={styles.root}>
          <SelectComponent
            multiple
            labelId="select-multiple-checkbox-label"
            selectId="select-multiple-checkbox"
            selectOptions={selectOptions}
            selectedValue={selectedPractices}
            handleChange={handlePracticeTypeSelectionChange}
            selectLabel={t('what-to-practice.select-1.label-text')}
            renderValue={(selected) => (selected as string[]).map((k) => t(k)).join(', ')}
            formControlSx={{ m: 1, mt: 2, minWidth: 216 }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WhatToPracticeSelector;
