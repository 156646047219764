import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { number } from './formatters';

export const supportedLngs = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  pt: 'Português',
  da: 'Dansk',
  ru: 'Русский',
  nl: 'Nederlands',
  sv: 'Svenska',
  pl: 'Polski',
  ar: 'العربية',
  zh: '中文',
  ur: 'اردو',
  hi: 'हिंदी',

  // ar: 'Arabic (العربية)',
};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: 'en',
    supportedLngs: Object.keys(supportedLngs),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // resources: {
    //   en: {
    //     translation: {
    //       // here we will place our translations...
    //     },
    //   },
    // },
  });
i18n.services.formatter?.add('number', number);
export default i18n;
