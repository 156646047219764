import { ChangeEvent, useEffect, useState } from 'react';
import {
  Grid2 as Grid,
  Typography,
  MenuItem,
  Box,
  SelectChangeEvent,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  ThemeProvider,
  Button,
} from '@mui/material';
import styles from './learn-table.module.scss';
import SelectComponent from '../shared/components/SelectComponent';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
import { DefaultTable, MaxAnswersToKeep, tNumber, practiceOptions, UpToTimesOptions } from '../shared/constants';
import { useAppSelector } from '../app/hooks';
import { whiteTheme } from './SelectionPage/WhatToPracticeSelector';
import { IAnswer, IQuestion } from './PracticePageUI';
import React from 'react';
import TextFieldComponent from '../shared/components/TextFieldComponent';
import { localizedNumberToEnglishNumber, localizeOperationChar } from '../shared/utils';

const LearnTables = () => {
  const { t } = useTranslation();
  const resolvedLocale = i18n.resolvedLanguage;
  const yourName = useAppSelector((state) => state.yourName);

  const yourContext = !!yourName ? 'name' : 'noname';
  enum TableLearningModeEnum {
    'learntable-page.mode-practice-table' = 'PracticeTable',
    'learntable-page.mode-show-table' = 'ShowTable',
  }
  useEffect(() => {
    document.title = t('learntable-page.title');
    if (learningMode === TableLearningModeEnum['learntable-page.mode-practice-table']) {
      generateQuestion(selectedTable, selectedUpToTimes);
    }
  }, []);

  const selectTableOptions = [...Array(20).keys()].map((m) => (
    <MenuItem key={m + 1} value={m + 1}>
      {t(tNumber, { val: m + 1 })}
    </MenuItem>
  ));

  const upToTimesOptions = UpToTimesOptions.map((m) => (
    <MenuItem key={m} value={m}>
      {t(tNumber, { val: m })}
    </MenuItem>
  ));

  const [selectedTable, setSelectedTable] = useState<string>(DefaultTable.toString());
  const [selectedUpToTimes, setSelectedUpToTimes] = useState<string>(UpToTimesOptions[2].toString());
  // const [selectedUpToTimes, setSelectedUpToTimes] = useState<string>(t(tNumber, { val: UpToTimesOptions[2] }));
  const [learningMode, setLearningMode] = useState<TableLearningModeEnum>(
    TableLearningModeEnum['learntable-page.mode-practice-table'],
  );
  const [answer, setAnswer] = useState<string>('');
  const [storedAnswers, setStoredAnswers] = useState<IAnswer[]>([]);
  const answerInput = React.createRef<HTMLInputElement>();

  const upToTimes = parseInt(selectedUpToTimes, 10);
  const [questionState, setQuestionState] = useState<IQuestion>({
    firstNumber: 0,
    secondNumber: 0,
    operationChar: practiceOptions.Additions,
    correctAnswer: 0,
    timeUsed: 0,
  });

  const handleTableChange = (e: SelectChangeEvent<unknown>) => {
    const selection = e.target.value as string;
    setSelectedTable(selection);
    generateQuestion(selection, selectedUpToTimes);
  };

  const storeAnswer = (answerToStore: string) => {
    // const timeUsed =
    //   Number.parseInt(allowedTime, 10) * 60 -
    //   timeLeft -
    //   storedAnswers.map((m) => m.timeUsed).reduce((a, b) => a + b, 0);
    const state = { ...questionState, answer: answerToStore };
    const storedAnswersArray = [...storedAnswers, state];
    if (storedAnswersArray.length > MaxAnswersToKeep) {
      storedAnswersArray.shift();
    }
    setStoredAnswers(storedAnswersArray);
  };

  const handleUpToTimesChange = (e: SelectChangeEvent<unknown>) => {
    const selection = e.target.value as string;
    setSelectedUpToTimes(selection);
    generateQuestion(selectedTable, selection);
  };

  function handleLearningModeChange(event: ChangeEvent<HTMLInputElement>, value: string): void {
    const mode = value as TableLearningModeEnum;
    setLearningMode(value as TableLearningModeEnum);
    if (mode === TableLearningModeEnum['learntable-page.mode-practice-table']) {
      generateQuestion(selectedTable, selectedUpToTimes);
    }
  }

  const isCorrect = (answerGiven: string, correctAnswer: number) =>
    localizedNumberToEnglishNumber(answerGiven, resolvedLocale) === correctAnswer;

  const generateQuestion = (selectedTable: string, selectedUpToTimes: string) => {
    if (selectedTable && selectedUpToTimes) {
      const firstNumber = parseInt(selectedTable, 10);
      let secondNumber = questionState.secondNumber;
      while (secondNumber === questionState.secondNumber) {
        secondNumber = Math.floor(Math.random() * parseInt(selectedUpToTimes)) + 1;
      }
      const operationChar = practiceOptions.Multiplications;
      const correctAnswer = firstNumber * secondNumber;
      const timeUsed = 0;
      setQuestionState({
        firstNumber,
        secondNumber,
        operationChar,
        correctAnswer,
        timeUsed,
      });
    }
  };

  const handleAnswer = (answerToStore: string) => {
    // Answer is stored in localized format,
    // When we compare the answer with correct answer,
    // answer given is converted into english number format
    // This will help to see if the answer is correct because
    // the correct answer is stored in english.
    // When you display the answer and correct answer, localize them.
    storeAnswer(answerToStore);
    generateQuestion(selectedTable, selectedUpToTimes);
    setAnswer('');
    answerInput?.current?.focus();
    // setTimePerQuestionLeft((_) => parseInt(timePerQuestion));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value);
    // const valNum = sanitizeLocalizedNumberString(e.target.value, resolvedLocale as string);
    // if (!isNaN(valNum)) {
    //   const valnumRes = t(NumLabel, { val: valNum });
    //   // its a number
    //   setAnswer(valnumRes);
    // } else {
    //   // its not a number
    //   setAnswer(e.target.value);
    // }
  };

  const buttonClasses = {
    root: {
      marginTop: '40px',
    },
  };

  const { firstNumber, secondNumber, operationChar } = questionState;
  const showForm = selectedTable && learningMode === TableLearningModeEnum['learntable-page.mode-practice-table'];
  const showTable = selectedTable && learningMode === TableLearningModeEnum['learntable-page.mode-show-table'];
  const wrongAnswers = storedAnswers.filter((a) => !isCorrect(a.answer, a.correctAnswer)).length;

  return (
    <>
      {/* <div>
        <p>Numbers intl</p>
        <p>{t('intlNumber', { val: 10000 })}</p>
        <p>{t('intlNumber', { val: 100000 })}</p>
        <p>{t('intlNumber', { val: 100000, lng: resolvedLocale })}</p>
        <p>{t('intlNumber', { val: 1000.3, lng: 'de' })}</p>
        <p>{t('intlNumber', { val: 1000.3, lng: 'de-DE' })}</p>
      </div> */}
      <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ marginTop: 2 }}>
        <Typography variant="h3" style={{ fontSize: 16, padding: '0 8px' }} className={styles.style} align="center">
          <p>
            {t('learntable-page.p1.text', {
              context: yourContext,
              yourName,
            })}
          </p>
        </Typography>
      </Grid>
      <Grid container sx={{ width: '100%' }}>
        <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ marginTop: 2 }}>
          <ThemeProvider theme={whiteTheme}>
            <FormControl
              sx={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}
              className={styles.style}
              style={{ padding: '0 8px' }}
            >
              <FormLabel sx={{ color: 'white' }}>{t('learntable-page.learning-mode-group.label')}</FormLabel>
              <RadioGroup
                aria-labelledby="learning-mode-radio-buttons-group"
                name="learning-mode"
                value={learningMode}
                onChange={handleLearningModeChange}
                row
                sx={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}
              >
                <FormControlLabel
                  value={TableLearningModeEnum['learntable-page.mode-practice-table']}
                  control={<Radio sx={{ color: 'white' }} />}
                  label={t('learntable-page.mode-practice-table')}
                />
                <FormControlLabel
                  value={TableLearningModeEnum['learntable-page.mode-show-table']}
                  control={<Radio sx={{ color: 'white' }} />}
                  label={t('learntable-page.mode-show-table')}
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <Box component="form">
          <SelectComponent
            className={styles.style}
            selectOptions={selectTableOptions}
            selectedValue={selectedTable}
            handleChange={handleTableChange}
            selectLabel={t('learntable-page.select-table-label')}
            formControlSx={{ minWidth: 120, m: 2 }}
            labelId="selectTableSelectLabel"
            selectId="selectTableSelectId"
          />
          <SelectComponent
            className={styles.style}
            selectOptions={upToTimesOptions}
            selectedValue={selectedUpToTimes}
            handleChange={handleUpToTimesChange}
            selectLabel={t('learntable-page.upto-times-select-label')}
            formControlSx={{ minWidth: 90, m: 2 }}
            labelId="selectTableSelectLabel"
            selectId="selectTableSelectId"
          />
        </Box>
      </Grid>

      {showForm && (
        <>
          <form
            hidden={!showForm}
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleAnswer(answer);
            }}
            className={`${styles.form}`}
          >
            <Grid container spacing={1} className={styles.style}>
              <Grid display="flex" justifyContent="flex-end" alignItems="center" size="grow">
                <Typography gutterBottom align="right" style={{ marginTop: 16 }}>
                  {t(tNumber, { val: firstNumber })} {localizeOperationChar(operationChar)}{' '}
                  {t(tNumber, { val: secondNumber })} ={' '}
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                <TextFieldComponent
                  variant="outlined"
                  type="number"
                  required
                  disabled={false}
                  id="standard-required"
                  label={t('learntable-page.answerinput.label')}
                  value={answer}
                  onChange={handleChange}
                  inputRef={answerInput}
                  autoFocus
                  key="answerInput1"
                />
              </Grid>
              <Grid display="flex" justifyContent="flex-start" alignItems="center" size="grow">
                <Box>
                  <Button
                    type="submit"
                    sx={{
                      ...buttonClasses,
                      '&.Mui-disabled': {
                        background: '#828282',
                        color: '#c0c0c0',
                      },
                    }}
                    variant="contained"
                    size="large"
                    color="secondary"
                    disabled={answer === ''}
                  >
                    {t('learntable-page.answerbutton.label')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
          {storedAnswers && storedAnswers.length > 0 && (
            <Grid container spacing={3} columns={3} className={styles.style} sx={{ marginTop: 2, width: '100%' }}>
              <Grid display="flex" justifyContent="flex-end" alignItems="center" size={1}>
                <Typography gutterBottom align="center">
                  {t('learntable-page.previous-answers.srno.label')}
                </Typography>
              </Grid>

              <Grid display="flex" justifyContent="center" alignItems="center" size={1}>
                <Typography gutterBottom align="center">
                  {t('learntable-page.previous-answers.quiz.label')}
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="flex-start" alignItems="center" size={1}>
                <Typography gutterBottom align="center" hidden={wrongAnswers <= 0}>
                  {t('learntable-page.previous-answers.answer.label')}
                </Typography>
              </Grid>
            </Grid>
          )}
          {[...storedAnswers].reverse().map(
            (
              {
                answer: ans,
                correctAnswer,
                // timeUsed,
                firstNumber: fn,
                secondNumber: sn,
                operationChar: oc,
              }: IAnswer,
              index,
            ) => (
              <Grid key={index} container spacing={3} columns={3} className={styles.style} sx={{ width: '100%' }}>
                <Grid display="flex" justifyContent="flex-end" alignItems="center" size={1}>
                  <Typography
                    style={{ textDecoration: 'none' }}
                    classes={{
                      root: styles.typoGraphyRight,
                    }}
                  >
                    {t(tNumber, { val: storedAnswers.length - index })})
                  </Typography>
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" size={1}>
                  <Typography
                    classes={{
                      root: !isCorrect(ans, correctAnswer) ? styles.typoGraphyWrong : styles.typoGraphyRight,
                    }}
                  >
                    {/* Given answer is already stored as locaized string to don't translate. */}
                    {t(tNumber, { val: fn })} {localizeOperationChar(oc)} {t(tNumber, { val: sn })} {t('equal-symbol')}{' '}
                    {t(tNumber, { val: ans })}
                  </Typography>
                </Grid>
                <Grid display="flex" justifyContent="flex-start" alignItems="center" size={1}>
                  <Typography hidden={isCorrect(ans, correctAnswer)}>{t(tNumber, { val: correctAnswer })}</Typography>
                </Grid>
              </Grid>
            ),
          )}
        </>
      )}
      {/* </Grid> */}

      <Grid container sx={{ margin: 1 }}>
        {showTable &&
          [...Array(upToTimes).keys()].map((m) => (
            <Grid
              display="flex"
              className={styles.style}
              justifyContent="center"
              alignItems="center"
              size={12}
              key={m}
              sx={{ width: '100%' }}
            >
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="right">{`${t(tNumber, { val: selectedTable })}`}</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="center">X</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="left">{t(tNumber, { val: m + 1 })}</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="left">=</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="left" className={styles.color}>
                  {t(tNumber, { val: Number.parseInt(selectedTable, 10) * (m + 1) })}
                </Box>
              </Typography>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default LearnTables;
