import NumberSelector from './NumberSelector';
import WhatToPracticeSelector from './WhatToPracticeSelector';
import NumberOfQuestionsComponent from './NumberOfQuestionsComponent';
import AllowedTime from './AllowedTime';
import StartPracticeButton from './StartPracticeButton';
import AllowedTimePerQuestion from './AllowedTimePerQuestion';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import YourNameTextField from './YourNameTextField';

const SelectionPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('selection-page.title');
  }, [t]);

  return (
    <div>
      <NumberSelector />
      <YourNameTextField />
      <WhatToPracticeSelector />
      <NumberOfQuestionsComponent />
      <AllowedTime />
      <AllowedTimePerQuestion />
      <StartPracticeButton />
    </div>
  );
};
export default SelectionPage;
