import { Grid2 as Grid, Typography, Button, Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import styles from './practice-page.module.scss';

import { useTranslation } from 'react-i18next';
import TextFieldComponent from '../shared/components/TextFieldComponent';
import { tNumber } from '../shared/constants';

export interface IQuestion {
  firstNumber: number;
  secondNumber: number;
  operationChar: string;
  correctAnswer: number;
  timeUsed: number;
}

export interface IAnswer extends IQuestion {
  answer: string;
}

const buttonClasses = {
  root: {
    marginTop: '40px',
  },
};

export interface IPracticePageUIProps {
  firstNumber: number;
  secondNumber: number;
  onAnswered: (answer: string) => void;
  operationChar: string;
  numberOfQuestions: number;
  timeLeft: number;
  toTimeString: (time: number) => string;
  toTimeTextString: (time: number) => string;
  handleStartAgainClick: (e: React.MouseEvent) => void;
  allowedTime: string;
  previousAnswers: IAnswer[];
  enabled: boolean;
  remainingTime: string;
  numOfQuestionsLeft: number;
  quizDone: boolean;
  isCorrect: (answerGiven: string, correctAnswer: number) => boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  answer: string;
  wrongAnswers: number;
  usedTime: number;
  answerInput: React.RefObject<HTMLInputElement>;
  questionTimeLeft: number;
  timePerQuestionEnabled: boolean;
  yourName: string;
  yourNameContext: string;
}

const PracticePageUI: React.FC<IPracticePageUIProps> = ({
  firstNumber,
  secondNumber,
  onAnswered,
  operationChar,
  previousAnswers,
  timeLeft,
  handleStartAgainClick,
  toTimeString,
  enabled,
  quizDone,
  isCorrect,
  handleChange,
  answer,
  wrongAnswers,
  answerInput,
  numberOfQuestions,
  toTimeTextString,
  allowedTime,
  remainingTime,
  numOfQuestionsLeft,
  usedTime,
  questionTimeLeft,
  timePerQuestionEnabled,
  yourName,
  yourNameContext,
}) => {
  const { t } = useTranslation();
  const getMessage = () => {
    if (enabled && timeLeft > 0) {
      return (
        <Typography variant="h3" style={{ fontSize: 16 }} className={styles.style} align="center">
          {t('practice-page.remaining-time-and_questions-message', {
            context: yourNameContext,
            yourName,
            remainingTime,
            numberOfQuestionsLeftCount: numOfQuestionsLeft,
          })}
        </Typography>
      );
    }

    return (
      <>
        <Typography className={styles.style} style={{ fontSize: 16 }}>
          {`${t('practice-page.test-finish-message', {
            count: 0,
            context: yourNameContext,
            yourName,
            answeredCount: previousAnswers.length,
            totalQuestionsCount: numberOfQuestions,
            timeUsed: toTimeTextString(timeLeft > 0 ? usedTime : Number.parseInt(allowedTime, 10) * 60),
          })} ${t('practice-page.test-finish-wrong-answers-message', {
            count: wrongAnswers,
            wrongAnswersCount: wrongAnswers,
          })}`}
        </Typography>
      </>
    );
  };

  const currentQuestionTimeMesage = () => {
    if (!timePerQuestionEnabled) return null;
    if (enabled && timeLeft > 0) {
      return (
        <Typography variant="h3" style={{ fontSize: 16 }} className={styles.style} align="center">
          {t('practice-page.time-for-question-message', {
            count: questionTimeLeft,
            questionTimeLeftCount: questionTimeLeft,
          })}
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      <Grid container sx={{ margin: 1, width: '100%' }}>
        <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ margin: 2 }}>
          {getMessage()}
        </Grid>
        <Grid size={12} display="flex" justifyContent="center" alignItems="center">
          {(timeLeft === 0 || !enabled) && (
            <Button
              style={{
                padding: '16px 35px',
                marginTop: '8px',
                marginBottom: '8px',
                maxHeight: 10,
              }}
              variant="contained"
              size="large"
              color="secondary"
              onClick={handleStartAgainClick}
              type="submit"
            >
              {t('practice-page.start-again-button.label')}
            </Button>
          )}
        </Grid>
      </Grid>
      <form
        hidden={quizDone}
        autoComplete="off"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onAnswered(answer);
        }}
        className={`${styles.form}`}
      >
        <Grid container spacing={1} className={styles.style}>
          <Grid display="flex" justifyContent="flex-end" alignItems="center" size="grow">
            <Typography gutterBottom align="right" style={{ marginTop: 16 }}>
              {t(tNumber, { val: firstNumber })} {operationChar} {t(tNumber, { val: secondNumber })} ={' '}
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
            <TextFieldComponent
              variant="outlined"
              type="number"
              required
              disabled={quizDone}
              id="standard-required"
              label={t('practice-page.answerinput.label')}
              value={answer}
              onChange={handleChange}
              inputRef={answerInput}
              autoFocus
              key="answerInput1"
            />
          </Grid>
          <Grid display="flex" justifyContent="flex-start" alignItems="center" size="grow">
            <Box>
              <Button
                type="submit"
                sx={{
                  ...buttonClasses,
                  '&.Mui-disabled': {
                    background: '#828282',
                    color: '#c0c0c0',
                  },
                }}
                variant="contained"
                size="large"
                color="secondary"
                disabled={answer === '' || quizDone}
              >
                {t('practice-page.answerbutton.label')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ margin: 2 }}>
        {currentQuestionTimeMesage()}
      </Grid>
      {previousAnswers && previousAnswers.length > 0 && (
        <Grid
          container
          columns={wrongAnswers <= 0 ? 2 : 3}
          spacing={3}
          hidden={quizDone}
          className={styles.style}
          sx={{ marginTop: 0, width: '100%' }}
        >
          <Grid display="flex" justifyContent={wrongAnswers <= 0 ? 'center' : 'flex-end'} alignItems="center" size={1}>
            <Typography gutterBottom align="center">
              {t('practice-page.previous-answers.time.label')}
            </Typography>
          </Grid>

          <Grid display="flex" justifyContent="center" alignItems="center" size={1}>
            <Typography gutterBottom align="center">
              {t('practice-page.previous-answers.quiz.label')}
            </Typography>
          </Grid>
          <Grid
            hidden={wrongAnswers <= 0}
            sx={{ display: wrongAnswers <= 0 ? 'none' : 'flex' }}
            justifyContent="flex-start"
            alignItems="center"
            size={1}
          >
            <Typography gutterBottom align="center">
              {t('practice-page.previous-answers.answer.label')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {[...previousAnswers]
        .reverse()
        .map(
          (
            { answer: ans, correctAnswer, timeUsed, firstNumber: fn, secondNumber: sn, operationChar: oc }: IAnswer,
            index,
          ) => (
            <Grid
              container
              spacing={3}
              columns={wrongAnswers <= 0 ? 2 : 3}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              hidden={quizDone}
              className={styles.style}
              sx={{ width: '100%' }}
            >
              <Grid
                display="flex"
                justifyContent={wrongAnswers <= 0 ? 'center' : 'flex-end'}
                alignItems="center"
                size={1}
              >
                <Typography
                  align="center"
                  style={{ textDecoration: 'none' }}
                  classes={{
                    root: styles.typoGraphyRight,
                  }}
                >
                  {t(tNumber, { val: previousAnswers.length - index })}) {toTimeString(timeUsed)}
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center" size={1}>
                <Typography
                  classes={{
                    root: !isCorrect(ans, correctAnswer) ? styles.typoGraphyWrong : styles.typoGraphyRight,
                  }}
                  align="center"
                >
                  {t(tNumber, { val: fn })} {oc} {t(tNumber, { val: sn })} {t('equal-symbol')}{' '}
                  {t(tNumber, { val: ans })}
                </Typography>
              </Grid>
              <Grid
                justifyContent="flex-start"
                alignItems="center"
                size={1}
                sx={{ display: wrongAnswers <= 0 ? 'none' : 'flex' }}
              >
                <Typography hidden={isCorrect(ans, correctAnswer)} align="center">
                  {t(tNumber, { val: correctAnswer })}
                </Typography>
              </Grid>
            </Grid>
          ),
        )}
      <div style={{ margin: 4 }}></div>
    </>
  );
};

export default PracticePageUI;
